import React, { useEffect, useState } from 'react';
import { useTournament } from './TournamentContext';
import axios from 'axios';
import config from "../config/default.json";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarPlus } from '@fortawesome/free-solid-svg-icons';

const base_url = config.base_url;

const Schedule = () => {
  const [divisions, setDivisions] = useState([]);
  const [teams, setTeams] = useState([]);
  const [games, setGames] = useState([]);
  const [selectedDivision, setSelectedDivision] = useState('');
  const [selectedTeam, setSelectedTeam] = useState('');
  const [sortedColumn, setSortedColumn] = useState('time'); // Set default sort column to 'time'
  const [sortOrder, setSortOrder] = useState('asc'); // Set default sort order to 'asc'
  const { tournamentID } = useTournament();


  useEffect(() => {
    if(tournamentID){
      fetchDivisions();
      fetchTeamsByDivision();
      fetchGamesbyTeam();
    }  
  },  [tournamentID,selectedDivision,selectedTeam]);

  const fetchDivisions = async () => {
    try {

      if (!selectedDivision){
        const response = await axios.get(base_url + `/api/divisions/${tournamentID}`);

        if ( response.data.length > 0) {
          setSelectedDivision(response.data[0]._id); // Set the selected division to the first ID in the list
        }
        setDivisions(response.data);

      }
     
    } catch (error) {
      console.error('Failed to fetch divisions', error);
    }
  };

  const fetchTeamsByDivision = async () => {
    try {

      if (!selectedDivision || selectedTeam) {
       
       // setTeams([]); // If no team is selected, clear the games data
        return;
      }

      let url = base_url + `/api/teams/${tournamentID}/division/${selectedDivision}`;
      
    
      const response = await axios.get(url);
     
      if (selectedTeam === '' && response.data.length > 0) {
        setSelectedTeam(response.data[0]._id); // Set the selected team to the first ID in the list
      }
      setTeams(response.data);
    } catch (error) {
      console.error('Failed to fetch teams', error);
    }
  };

  const fetchGamesbyTeam = async () => {
    try {


      if (!selectedTeam) {
        setGames([]); // If no team is selected, clear the games data
        return;
      }

     
        let url = `/api/schedulegames/team/${tournamentID}/${selectedTeam}`;
      

      const response = await axios.get(url);
      setGames(response.data);
      
    } catch (error) {
      console.error(error);
    }
  };




  const handleDivisionChange = (e) => {
    setSelectedDivision(e.target.value);
    setSelectedTeam('');
  };

  const handleTeamChange = (e) => {
    setSelectedTeam(e.target.value);
  };

  const handleSort = (column) => {
    if (column === sortedColumn) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortedColumn(column);
      setSortOrder('asc');
    }
  };

  const timeslots = ["08:30","08:55","09:20","09:45","10:10","10:35","11:00","11:25",
  "11:50","12:15","13:00","13:25","13:50","14:15"];


  function tConvert (time) {
    if(time){
    // Check correct time format and split into components
    time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) { // If time format correct
      time = time.slice (1);  // Remove full string match value
      time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
      
    }
    return time.join (''); // return adjusted time or original string
    }

  }

  function tCerateEndTime (time) {
    // Check correct time format and split into components
    time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
  //console.log(time);
    if (time.length > 1) { // If time format correct
      time = time.slice (1);  // Remove full string match value
      time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
      time[2] = parseInt(time[2])+20;
      
    }
    return time.join (''); // return adjusted time or original string
  }
  const sortGamesByTime = (games) => {
    return games.sort((a, b) => {
      //console.log("time: "+ a.time + " " + b.time)
      // const timeA = new Date(a.time).getHours();
      // const timeB = new Date(b.time).getTime();
      // console.log(" converted time: "+ timeA + " " + timeB)
      if (a.time < b.time) {
        return sortOrder === 'asc' ? -1 : 1;
      }
      if (a.time > b.time) {
        return sortOrder === 'asc' ? 1 : -1;
      }
      return 0;
    });
  };

 
 let sortedGames = [...games]; // Create a copy of the games array

  if (sortedColumn === 'time') {
    sortedGames = sortGamesByTime(sortedGames);
  } else if (sortedColumn) {
    sortedGames.sort((a, b) => {
      const valueA = a[sortedColumn].name.toLowerCase();
      const valueB = b[sortedColumn].name.toLowerCase();
      if (valueA < valueB) {
        return sortOrder === 'asc' ? -1 : 1;
      }
      if (valueA > valueB) {
        return sortOrder === 'asc' ? 1 : -1;
      }
      return 0;
    });
  }

  const createRows = () => {
    const sortedGames = sortGamesByTime(games); // Sort the games by time
  
    const rows = timeslots.map((timeslot) => {
      const game = sortedGames.find((game) => game.time === timeslot && (game.team1?._id === selectedTeam || game.team2?._id === selectedTeam));
  
      if (game) {
        return (
          <tr key={timeslot} className={(game.isReferee === true) ? 'bye-ref-row' : ''}>
            <td>{tConvert(game.time)}</td>
            <td>  {game.isReferee === true ? 'Referee' : (game.team1?._id === selectedTeam ? game.team2?.name : game.team1?.name)}
         </td>
            <td>{game.court}</td>
          </tr>
        );
      } else {
        return (
          <tr key={timeslot} className='bye-row'>
            <td>{tConvert(timeslot)}</td>
            <td>BYE</td>
            <td></td>
          </tr>
        );
      }
    });
  
    return rows;
  };

  return (
    <div>
      <h1>
        <FontAwesomeIcon icon={faCalendarPlus} size="1x"/> &nbsp;
      Schedules</h1> 
      <div>
      <h3>
      <br/>
        <label>
          Division:
          <select value={selectedDivision} onChange={handleDivisionChange}>
            {divisions.map((division) => (
              <option key={division._id} value={division._id}>
                {division.name}
              </option>
            ))}
          </select>
        </label>
        &nbsp;  &nbsp;
        <label>
          Team:
          <select value={selectedTeam} onChange={handleTeamChange}>
          
            {teams.map((team) => (
              <option key={team._id} value={team._id}>
                {team.name}
              </option>
            ))}
          </select>
        </label>
        </h3>
  
      </div>
      <div>
        <table className="schedule-table">
          <thead>
            <tr>
            <th
                className={sortedColumn === 'time' ? `sortable ${sortOrder}` : 'sortable'}
                onClick={() => handleSort('time')}
              >
                Time
              </th>            
              <th>
                Opponent
              </th>
              <th>
                Court
              </th>                          
            </tr>
          </thead>
          <tbody>
          {createRows()}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Schedule;
