import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments,faTrophy, faMedal,faVolleyball } from '@fortawesome/free-solid-svg-icons';

import config from '../config/default.json';

const base_url = config.base_url;

const Home = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [tournaments, setTournaments] = useState([]);
  const [filteredTournaments, setFilteredTournaments] = useState([]);

  // Function to fetch tournaments from your API
  const fetchTournaments = async () => {
    try {
      const response = await fetch(base_url + '/api/tournaments/published');
      if (!response.ok) {
       alert('Network response was not ok');
      }
      const data = await response.json();
      setTournaments(data);
      setFilteredTournaments(data);
    } catch (error) {
      console.error('Error fetching tournaments:', error);
    }
  };

  useEffect(() => {
    fetchTournaments();
  }, []);

  // Function to handle the search input change
  const handleSearchInput = (e) => {
    const value = e.target.value;
    setSearchQuery(value);

    // Filter tournaments dynamically as the user types
    const filteredTournaments = tournaments.filter((tournament) =>
      tournament.name.toLowerCase().includes(value.toLowerCase()) ||
      tournament.acronym.toLowerCase().includes(value.toLowerCase()) ||
      tournament.location.toLowerCase().includes(value.toLowerCase()) ||
      tournament.year.toString().includes(value) ||
      tournament.winner.toString().includes(value)
    );
    setFilteredTournaments(filteredTournaments);
  };

  return (
    <div className="home">
      <h1>Volleyball Scorecards</h1>
      <h3>Lets tally game schedules and scorecard online like never before. Provide live data to your users and get better engagement and excitement going. Transform your tournament record keeping and overall expereience of players.</h3>
      <br />
      <div className="search-box-container">
        <input
          type="text"
          className="search-box"
          placeholder="Search Tournaments..."
          value={searchQuery}
          onChange={handleSearchInput}
        />
      </div>
      <br />
      <h3>Select a tournament card below to view data.</h3>

      <div className="card-container">
        {filteredTournaments.map((tournament) => (
          <Link to={`/${tournament.linkindex}`} key={tournament._id}>
            <div key={tournament._id} className="tournament-card">
              <table>
                <thead></thead>
                <tbody>
                  <tr>
                    <td  colSpan="2" style={{ fontWeight: 'bold', color: 'purple', fontSize: '20px', textAlign: 'center' }}>
                      {tournament.name} 
                    </td>
                  </tr>
                  <tr style={{ color: 'black' }}>
                    <td>
                      Year: {tournament.year}
                    </td>
                    <td>
                      Location: {tournament.location}
                    </td>
                  </tr>
                  {/* <tr style={{ color: 'black' }}>
                    <td colSpan="2" >
                    Description: {tournament.description}
                    </td>
                  </tr> */}
                  { (tournament.winner?.name ) ? (
                  <tr>  
                     <td style={{ color: 'black' }}>
                      winner:
                    </td>                
                    <td style={{ fontWeight: 'bold', color: 'purple', fontSize: '20px' }}>
                     {tournament.winner?.name}&nbsp; 
                      <FontAwesomeIcon icon={faTrophy}  size="1x" style={{ color: 'purple', marginRight: '10px'}} />
                    </td>
                  </tr>):(
                    <tr>  
                    <td style={{ color: 'black' }}>
                     winner:
                   </td>                
                   <td style={{ fontWeight: 'bold', color: 'purple', fontSize: '20px' }}>
                    TBD&nbsp; 
                     </td>
                 </tr>
                  )}
                  <tr>
                  <td colSpan="2" style={{ textAlign: 'center' }}>
                    <Link to={`/${tournament.linkindex}`} className="select-button">
                    <FontAwesomeIcon icon={faVolleyball} spin pulse /> 
                    &nbsp;   {tournament.acronym} &nbsp; {tournament.year}  &nbsp; 
                      <FontAwesomeIcon icon={faVolleyball} spin pulse /> 
                    </Link>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </Link>
        ))}
      </div>
      <div>
        <br />
          <br /> 
          <h2 className='home-button'>
          <Link to="./feedback" >
            <FontAwesomeIcon icon={faComments} />
            &nbsp; Feedback
          </Link>  
          </h2>
    </div>
    </div>
  
  );
};

export default Home;
