// mainRoutes.js
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from '../components/Home';
import TournamentHome from '../components/TournamentHome';
import Admin from '../components/Admin';
import TempAdmin from '../components/TempAdmin';
import Divisions from '../components/Divisions';
import Teams from '../components/Teams';
import Tournaments from '../components/Tournaments';
import AddTournament from '../components/AddTournament';
import UpdateTournament from '../components/UpdateTournament';
import Standings from '../components/Standings';
import AdminStandings from '../components/AdminStandings';
import AdminFeedback from '../components/AdminFeedback';
import Scores from '../components/Scores';
import WildCard from '../components/WildCard';
import Playoff from '../components/Playoff';
import ManagePlayoff from '../components/ManagePlayoff';
import AddPlayoffGame from '../components/AddPlayoffGame';
import Schedules from '../components/Schedules';
import AddDivision from '../components/AddDivision';
import AddTeam from '../components/AddTeam';
import UpdateTeam from '../components/UpdateTeam';
import AddGame from '../components/AddGame';
import UploadGameData from '../components/UploadGameData';
import AddSchedule from '../components/AddSchedule';
import AddScore from '../components/AddScore';
import Feedback from '../components/Feedback';
import HomeFeedback from '../components/HomeFeedback';
import Documents from '../components/documents'
import ManageTeams from '../components/ManageTeams';
import Share from '../components/Share';


export const MainRoutes = () => {
  return (
    <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/feedback" element={<HomeFeedback />} />
            {/* <Route path="/tournaments" element={<Tournaments />} /> */}
            <Route path="/:linkindex" element={<TournamentHome />} />
            <Route path="/:linkindex/schedules" element={<Schedules />} />
            <Route path="/:linkindex/scores" element={<Scores />} />
            <Route path="/:linkindex/wildcard" element={<WildCard />} />
            <Route path="/:linkindex/playoff" element={<Playoff />} />
            <Route path="/:linkindex/divisions" element={<Divisions />} />
            <Route path="/:linkindex/teams" element={<Teams />} />
            <Route path="/:linkindex/standings" element={<Standings />} />
            <Route path="/:linkindex/feedback" element={<Feedback />} />
            {/* <Route path="/admin/" element={<Admin />} /> */}
            <Route path="/admin/:linkindex/admin" element={<TempAdmin />} />
            <Route path="/admin/:linkindex/update-tournament" element={<UpdateTournament />} />
            <Route path="/admin/:linkindex/add-division" element={<AddDivision />} />
            <Route path="/admin/:linkindex/add-team" element={<AddTeam />} />
            <Route path="/admin/:linkindex/manage-team" element={<ManageTeams />} />
            <Route path="/admin/:linkindex/update-team" element={<UpdateTeam />} />
            <Route path="/admin/:linkindex/add-game" element={<AddGame />} />
            <Route path="/admin/:linkindex/upload-game" element={<UploadGameData />} />
            <Route path="/admin/:linkindex/add-score" element={<AddScore />} />
            <Route path="/admin/:linkindex/add-playoffgame" element={<AddPlayoffGame />} />
            <Route path="/admin/:linkindex/manage-playoff" element={<ManagePlayoff />} />
            <Route path="/admin/:linkindex/admin-standings" element={<AdminStandings />} />
            <Route path="/admin/:linkindex/admin-feedback" element={<AdminFeedback />} />
            <Route path="/admin/:linkindex/share" element={<Share />} />

            <Route path="/admin/mjz/admin" element={<Admin />} />
            <Route path="/admin/baps/admin" element={<TempAdmin />} />
            <Route path="/admin/add-tournament" element={<AddTournament />} />
            
            {/* <Route path="/admin/:linkindex/add-schedule" element={<AddSchedule />} /> */}
            <Route path="/loaderio-f566d198e1c407955b1d6cabdfd17307/" element={<Documents />} />
    </Routes>
       
  );
};
