import React, { useState, useEffect } from 'react';
import { useTournament } from './TournamentContext';
import axios from 'axios';
import config from "../config/default.json";
const base_url = config.base_url;

const AddGame = () => {
  const [teams, setTeams] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const [isReferee, setisReferee] = useState(false);
  const [selectedTeam1, setSelectedTeam1] = useState('');
  const [selectedTeam2, setSelectedTeam2] = useState('');
  const [selectedDivision, setSelectedDivision] = useState('');
  const [court, setCourt] = useState('');
  const [round, setRound] = useState('');
  const [time, setTime] = useState('');
  const [scoreTeam1, setScoreTeam1] = useState('0');
  const [scoreTeam2, setScoreTeam2] = useState('0');
  const { tournamentID } = useTournament();


  useEffect(() => {
    if(tournamentID){
      fetchTeams();
      fetchDivisions();
    }
   
  }, [tournamentID]);

  const fetchTeams = async () => {
    try {
      const response = await axios.get(base_url + `/api/teams/${tournamentID}`);
      setTeams(response.data);
    } catch (error) {
      console.error('Failed to fetch teams', error);
    }
  };

  const fetchTeamsByDivision = async (divisionId) => {
    try {
      const response = await axios.get(
        base_url + `/api/teams/${tournamentID}/division/${divisionId}`
      );
      setTeams(response.data);
    } catch (error) {
      console.error('Failed to fetch teams', error);
    }
  };

  const fetchDivisions = async () => {
    try {
      const response = await axios.get(base_url + `/api/divisions/${tournamentID}`);
      setDivisions(response.data);
    } catch (error) {
      console.error('Failed to fetch divisions', error);
    }
  };

  const handleTeam1Change = (e) => {
    if (isReferee) {
      setSelectedTeam1(e.target.value);
      setSelectedTeam2(e.target.value);
      console.log(selectedTeam2);
    } else {
      setSelectedTeam1(e.target.value);
    }
  };

  const handleTeam2Change = (e) => {
    setSelectedTeam2(e.target.value);
  };

  
  const handleisRefereeChange = (e) => {
    const isChecked = e.target.checked;
    setisReferee(isChecked);
  
    if (isChecked) {
      setSelectedTeam2(selectedTeam1); // Set selectedTeam2 to the value of selectedTeam1 when isReferee is checked
    } else {
      setSelectedTeam2(''); // Reset selectedTeam2 when isReferee is unchecked
    }
  };

  const handleDivisionChange = (e) => {
    const divisionId = e.target.value;
    setSelectedDivision(divisionId);
    if(!divisionId){
      fetchTeams();
    } else{
      fetchTeamsByDivision(divisionId);
    }
  
  };

  const handleCourtChange = (e) => {
    setCourt(e.target.value);
  };

  const handleRoundChange = (e) => {
    setRound(e.target.value);
  };

  const handleTimeChange = (e) => {
    setTime(e.target.value);
  };

  const handleScoreTeam1Change = (e) => {
    setScoreTeam1(e.target.value);
  };

  const handleScoreTeam2Change = (e) => {
    setScoreTeam2(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      await axios.post(base_url + '/api/games', {
        tournament: tournamentID,
        team1: selectedTeam1,
        team2: selectedTeam2,
        division: selectedDivision,
        isReferee: isReferee,
        round:round,
        court: court,
        time: time,
        scoreTeam1,
        scoreTeam2,
      });
      setSelectedTeam1('');
      setSelectedTeam2('');
      setSelectedDivision('');
      setScoreTeam1('0');
      setScoreTeam2('0');
      setCourt('0');
      setRound('0');
      setTime('');
      alert('Game added successfully!');
     
    } catch (error) {
      console.error('Failed to add game', error);
      alert('ERROR: Game not added. ' + error.response.data.error);
    }
  };

  return (
    <div>
      <h2>Add Game</h2>
      <form onSubmit={handleSubmit}>
      <label>
          Division:<br />
          <select value={selectedDivision} onChange={handleDivisionChange} required>
            <option value="">Select Division</option>
            {divisions.map((division) => (
              <option key={division._id} value={division._id}>
                {division.name}
              </option>
            ))}
          </select>
        </label>
        <br />
        <br />
        <br />
        <label className="feedback-label">
          Is Referee Game:
          <input
            type="checkbox"
            checked={isReferee}
            onChange={handleisRefereeChange} // Toggle the isReferee value
          />
        </label>
        <br />
       
        <label>
          Team 1:<br />
          <select value={selectedTeam1} onChange={handleTeam1Change}>
            <option value="">Select Team</option>
            {teams.map((team) => (
              <option key={team._id} value={team._id}>
                {team.name}
              </option>
            ))}
          </select>
          <br />
          <br />
        </label>

           {/* Conditionally render Team 2 dropdown if it's not a referee game */}
           {!isReferee && (
          <label>
            Team 2:<br />
            <select value={selectedTeam2} onChange={handleTeam2Change}>
              <option value="">Select Team</option>
              {teams.map((team) => (
                <option key={team._id} value={team._id}>
                  {team.name}
                </option>
              ))}
            </select>
          </label>
        )}
        <br />
        <br />
        <label>
          Court Number:<br />
          <input type="number" value={court} onChange={handleCourtChange} />
        </label>
        <br />
        <br />
        <label>
          Time:<br />
          <input type="time" value={time} onChange={handleTimeChange} />
        </label>
        <br />
        <br />
        <label>
          Score Team 1:<br />
          <input
            type="number"
            value={scoreTeam1}
            onChange={handleScoreTeam1Change}
          />
        </label>
        <br />
        <br />
        <label>
          Score Team 2:<br />
          <input
            type="number"
            value={scoreTeam2}
            onChange={handleScoreTeam2Change}
          />
        </label>
        <br />
        <br />
        <label>
          Round:<br />
          <input type="number" value={round} onChange={handleRoundChange} />
        </label>
        <br />
        <br />
        <button type="submit">Add Game</button>
      </form>
    </div>
  );
};

export default AddGame;
