import React, { useEffect, useState } from 'react';
import { useTournament } from './TournamentContext';
import axios from 'axios';
import config from "../config/default.json";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarPlus } from '@fortawesome/free-solid-svg-icons';

const base_url = config.base_url;

const Schedule = () => {
  const [divisions, setDivisions] = useState([]);
  const [teams, setTeams] = useState([]);
  const [games, setGames] = useState([]);
  const [selectedDivision, setSelectedDivision] = useState('');
  const [selectedTeam, setSelectedTeam] = useState('');
  const [sortedColumn, setSortedColumn] = useState('time'); // Set default sort column to 'time'
  const [sortOrder, setSortOrder] = useState('asc'); // Set default sort order to 'asc'
  const { tournamentID } = useTournament();

  useEffect(() => {
    if (tournamentID) {
      fetchDivisions();
      fetchTeamsByDivision();
      fetchGamesbyTeam();
    }
  }, [tournamentID, selectedDivision, selectedTeam]);

  const fetchDivisions = async () => {
    try {
      if (!selectedDivision) {
        const response = await axios.get(`${base_url}/api/divisions/${tournamentID}`);
        if (response.data.length > 0) {
          setSelectedDivision(response.data[0]._id); // Set the selected division to the first ID in the list
        }
        setDivisions(response.data);
      }
    } catch (error) {
      console.error('Failed to fetch divisions', error);
    }
  };

  const fetchTeamsByDivision = async () => {
    try {
      if (!selectedDivision || selectedTeam) {
        return;
      }
      const url = `${base_url}/api/teams/${tournamentID}/division/${selectedDivision}`;
      const response = await axios.get(url);
      if (selectedTeam === '' && response.data.length > 0) {
        setSelectedTeam(response.data[0]._id); // Set the selected team to the first ID in the list
      }
      setTeams(response.data);
    } catch (error) {
      console.error('Failed to fetch teams', error);
    }
  };

  const fetchGamesbyTeam = async () => {
    try {
      if (!selectedTeam) {
        setGames([]); // If no team is selected, clear the games data
        return;
      }
      const url = `/api/schedulegames/team/${tournamentID}/${selectedTeam}`;
      const response = await axios.get(url);
      setGames(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDivisionChange = (e) => {
    setSelectedDivision(e.target.value);
    setSelectedTeam('');
  };

  const handleTeamChange = (e) => {
    setSelectedTeam(e.target.value);
  };

  const handleSort = (column) => {
    if (column === sortedColumn) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortedColumn(column);
      setSortOrder('asc');
    }
  };

  // Time conversion helper function
  function tConvert(time) {
    if (time) {
      // Check correct time format and split into components
      time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
      if (time.length > 1) { // If time format correct
        time = time.slice(1);  // Remove full string match value
        time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
      }
      return time.join(''); // return adjusted time or original string
    }
  }

  // Sort the games by time field
  const sortGamesByTime = (games) => {
    return games.sort((a, b) => {
      if (a.time < b.time) {
        return sortOrder === 'asc' ? -1 : 1;
      }
      if (a.time > b.time) {
        return sortOrder === 'asc' ? 1 : -1;
      }
      return 0;
    });
  };

 
  // Create the table rows dynamically based on sorted games
 // Helper function to calculate the time difference in minutes between two times (HH:MM format)
const timeDifferenceInMinutes = (time1, time2) => {
  const [hours1, minutes1] = time1.split(':').map(Number);
  const [hours2, minutes2] = time2.split(':').map(Number);

  const date1 = new Date();
  date1.setHours(hours1, minutes1);

  const date2 = new Date();
  date2.setHours(hours2, minutes2);

  return (date2 - date1) / 60000; // Difference in minutes
};

// Create the table rows dynamically based on sorted games
// Create the table rows dynamically based on sorted games
const createRows = () => {
  // Reset the rows array on every call
  let rows = [];

  const sortedGames = sortGamesByTime(games); // Sort the games by time

  sortedGames.forEach((game, index) => {
    // Add the current game row
    rows.push(
      <tr key={`${game._id}-${index}`} className={game.isReferee ? 'bye-ref-row' : ''}>
        <td>{tConvert(game.time)}</td>
        <td>{game.isReferee ? 'Referee' : (game.team1?._id === selectedTeam ? game.team2?.name : game.team1?.name)}</td>
        <td>{game.court}</td>
      </tr>
    );

    // Check the gap between this game and the next one
    const nextGame = sortedGames[index + 1];
    if (nextGame) {
      const timeDiff = timeDifferenceInMinutes(game.time, nextGame.time);

      // If the gap between this game and the next one is more than 25 minutes, insert a "BYEE" row
      if (timeDiff > 35) {
        rows.push(
          <tr key={`BYE-${game._id}-${index}`} className="bye-row">
            <td colSpan="3" style={{ textAlign: 'center'}} >BYE</td>
          </tr>
        );
      }
    }
  });

  // If no games exist, show a message
  if (rows.length === 0) {
    rows.push(
      <tr key="no-games" className="bye-row">
        <td colSpan="3">No games available</td>
      </tr>
    );
  }

  return rows;
};


  return (
    <div>
      <h1>
        <FontAwesomeIcon icon={faCalendarPlus} size="1x" /> &nbsp; Schedules
      </h1>
      <div>
        <h3>
          <br />
          <label>
            Division:
            <select value={selectedDivision} onChange={handleDivisionChange}>
              {divisions.map((division) => (
                <option key={division._id} value={division._id}>
                  {division.name}
                </option>
              ))}
            </select>
          </label>
          &nbsp; &nbsp;
          <label>
            Team:
            <select value={selectedTeam} onChange={handleTeamChange}>
              {teams.map((team) => (
                <option key={team._id} value={team._id}>
                  {team.name}
                </option>
              ))}
            </select>
          </label>
        </h3>
      </div>
      <div>
        <table className="schedule-table">
          <thead>
            <tr>
              <th
                className={sortedColumn === 'time' ? `sortable ${sortOrder}` : 'sortable'}
                onClick={() => handleSort('time')}
              >
                Time
              </th>
              <th>Opponent</th>
              <th>Court</th>
            </tr>
          </thead>
          <tbody>{createRows()}</tbody>
        </table>
      </div>
    </div>
  );
};

export default Schedule;
