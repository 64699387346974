// Home.js

import React from 'react';
import { useTournament } from './TournamentContext';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faUsers, faGamepad, faCalendarPlus, faPlusSquare, faTrophy, faMedal } from '@fortawesome/free-solid-svg-icons';


const TempAdmin = () => {
  const { linkindex } = useTournament();

  return (
    <div className="home">
      <h1>Welcome Admin</h1>
      <p>This is the Admin page of the VolleyTally application.</p>
      <h2 className="home-button">
          <br />
          <br />
          <Link to={`/admin/${linkindex}/add-division`}>
            <FontAwesomeIcon icon={faPlusSquare} />
            &nbsp; Add Division
          </Link>
          <br />
          <br />
          <Link to={`/admin/${linkindex}/add-team`} >
            <FontAwesomeIcon icon={faUsers} />
            &nbsp; Add Team
          </Link>
          <br />
          <br />
          <Link to={`/admin/${linkindex}/update-team`} >
            <FontAwesomeIcon icon={faUsers} />
            &nbsp; Update Team
          </Link>
          <br />
          <br />
          <Link to={`/admin/${linkindex}/add-game`} >
            <FontAwesomeIcon icon={faGamepad} />
            &nbsp; Add Game
          </Link>
          <br />
          <br />
          <Link to={`/admin/${linkindex}/upload-game`} >
            <FontAwesomeIcon icon={faGamepad} />
            &nbsp; Upload Game
          </Link>
          <br />
          <br />
          <Link to={`/admin/${linkindex}/add-score`} >
            <FontAwesomeIcon icon={faTrophy} />
            &nbsp; Add Score
          </Link> 
          <br />
          <br />  
          <Link to={`/admin/${linkindex}/add-playoff`} >
            <FontAwesomeIcon icon={faMedal} />
            &nbsp; Add Playoff
          </Link>   
          <br />
          <br />  
          <Link to={`/admin/${linkindex}/add-playoffGame`} >
            <FontAwesomeIcon icon={faMedal} />
            &nbsp; Add New Playoff Game
          </Link>
         
      </h2>
    </div>
  );
};

export default TempAdmin;
