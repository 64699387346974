import React, { useEffect, useState } from 'react';
import { useTournament } from './TournamentContext';
import axios from 'axios';
import config from "../config/default.json";


const base_url = config.base_url;

const AddPlayoff = () => {
  const { tournamentID } = useTournament();
  const [teams, setTeams] = useState([]);
  const [playoffgames, setPlayOffGames] = useState([]);
  const [gameScores, setGameScores] = useState({});
  const [dropCount, setDropCount] = useState(0);
  const [wildcardCount, setwildcardCount] = useState(0);


 
  useEffect(() => {
    if(tournamentID){
      fetchTeams();
      fetchPlayoffGames();  
    }
  }, [tournamentID]);

  const fetchTeams = async () => {
    try {
      let url = base_url + `/api/teams/${tournamentID}`;
    
      const response = await axios.get(url);
      setTeams(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchPlayoffGames = async () => {
    try {
      let url = base_url + `/api/playoffgames/all/${tournamentID}`;
     
      const response = await axios.get(url);
      setPlayOffGames(response.data);
    } catch (error) {
      console.error(error);
    }
  };


  const handleScoreChange = (e, gameId, team) => {
    const { value } = e.target;
    setGameScores((prevGameScores) => ({
      ...prevGameScores,
      [gameId]: {
        ...prevGameScores[gameId],
        [`scoreTeam${team}`]: value,
      },
    }));
  };

  const handleCourtChange = (e, gameId) => {
    const { value } = e.target;
    setGameScores((prevGameScores) => ({
      ...prevGameScores,
      [gameId]: {
        ...prevGameScores[gameId],
        court: value,
      },
    }));
  };

  
  
  const updateAllPlayoffGame = async () => {
    try {
      const updatedGames = await Promise.all(
        playoffgames.map(async (game) => {
          const { scoreTeam1, scoreTeam2, court, rank } = gameScores[game._id] || {};
          
          if (scoreTeam1 !== undefined && scoreTeam2 !== undefined) {
            await axios.put(base_url + `/api/playoffgames/${tournamentID}/${game._id}`, {
              scoreTeam1,
              scoreTeam2,
              court,
            });
          } else if (court !== undefined ) {
            await axios.put(base_url + `/api/playoffgames//${tournamentID}/${game._id}`, {
              court,
            });
          } 
          
        })
      );
  
      fetchPlayoffGames();
      setGameScores({});
      alert('Playoff Games updated successfully!');
    } catch (error) {
      console.error('Failed to update Playoff game', error);
    }
  };
  


  const sortedTeams = teams.sort((a, b) => {
    if (a.totalWins === b.totalWins) {
      if (a.cumulativePointDifference === b.cumulativePointDifference) {
        // If points and point difference are the same, sort by totalPoints
        if (a.totalPoints === b.totalPoints) {
          // If totalPoints are also the same, sort by team name
          return a.name.localeCompare(b.name);
        }
        return b.totalPoints - a.totalPoints;
      }
      return b.cumulativePointDifference - a.cumulativePointDifference;
    }
    return b.totalWins - a.totalWins;
  });

  const createWildCardGames = async () => {

    try {
      const response = await axios.delete(base_url+`/api/wildcardgames/`+tournamentID);
    } catch (error) {
      console.error('Failed to delete playoff games', error);
     
    }

    const teamsToPlay = sortedTeams.slice(wildcardCount-2, sortedTeams.length);
    
    if (teamsToPlay.length > 1) {
      const games = [];
   
      for (let i = 0; i < teamsToPlay.length/2; i++) {
        const teamA = teamsToPlay[i];
        const teamB = teamsToPlay[teamsToPlay.length - i - 1];
        const court = [2,3,4,5,6,7];

  
        try {
          const response = await axios.post(base_url + `/api/playoffgames/`+tournamentID, {
            team1: teamA,
            team2: teamB,
            isWildCard: true,
            rank: i+1,
           // court: court[i],
          });
         
          games.push(response.data);
  
        } catch (error) {
          console.error('Failed to create WildCard games', error);
          alert('Failed to add WildCard Games. '+ error);
        }
       
      }
      fetchPlayoffGames();
      alert('WildCard Games added successfully!');

    
    }
  };

  const createPlayoffGamesWildCard = async () => {

     try {
      const response = await axios.delete(base_url+`/api/playoffgames/`+tournamentID);
      
    } catch (error) {
      console.error('Failed to delete playoff games', error);

    }
    const topplayoffteams = sortedTeams.slice(0, sortedTeams.length - dropCount);

    const wildcardGamesResponse = await axios.get(base_url + `/api/wildcardgames/`+tournamentID);
      const wildcardGames = wildcardGamesResponse.data;
      //console.log(wildcardGames); 
      // Determine winner teams from wildcard games
      const WildCardwinnerTeams = wildcardGames.map(game => {
        if (game.scoreTeam1>game.scoreTeam2) {
          return game.team1;
        } else {
          return game.team2;
        }
      });
  
    const teamsToPlay = [...topplayoffteams,...WildCardwinnerTeams];
        
    if (teamsToPlay.length > 1) {
      const games = [];
      const rankaound1 = [1,8,6,4,3,5,7,2];
      const roundrank = [
        [2,1],
        [2,2],
        [2,3],
        [2,4],
        [3,1],
        [3,2],
        [4,1]
      ]

      for (let i = 0; i < teamsToPlay.length/2; i++) {
        const teamA = teamsToPlay[i];
        const teamB = teamsToPlay[teamsToPlay.length - i - 1];
        const rank = rankaound1[i];
  
        try {
          const response = await axios.post(base_url + `/api/playoffgames/`+tournamentID, {
            team1: teamA,
            team2: teamB,
            isWildCard: false,
            round: 1,
            rank: rank,
          });
         
          games.push(response.data);
  
        } catch (error) {
          console.error('Failed to create round 1 games', error);
        }
      }

      for (const [round, rank] of roundrank) {
        try {
          await axios.post(base_url + `/api/playoffgames/`+tournamentID, {
            round: round,
            rank: rank,
          });
        } catch (error) {
          console.error('Failed to create games', error);
        }
      }
     
    }
    fetchPlayoffGames();
    alert('Playoff Games added successfully!');
    
  };

  const createPlayoffGames = async () => {

    try {
     const response = await axios.delete(base_url+`/api/playoffgames/`+tournamentID);
     
   } catch (error) {
     console.error('Failed to delete playoff games', error);

   }
   const teamsToPlay = sortedTeams.slice(0, sortedTeams.length - dropCount);

       
   if (teamsToPlay.length > 1) {
     const games = [];
     const rankaound1 = [1,8,6,4,3,5,7,2];
     const roundrank = [
       [2,1],
       [2,2],
       [2,3],
       [2,4],
       [3,1],
       [3,2],
       [4,1]
     ]

     for (let i = 0; i < teamsToPlay.length/2; i++) {
       const teamA = teamsToPlay[i];
       const teamB = teamsToPlay[teamsToPlay.length - i - 1];
       const rank = rankaound1[i];
 
       try {
         const response = await axios.post(base_url + `/api/playoffgames/`+tournamentID, {
           team1: teamA,
           team2: teamB,
           isWildCard: false,
           round: 1,
           rank: rank,
         });
        
         games.push(response.data);
 
       } catch (error) {
         console.error('Failed to create round 1 games', error);
       }
     }

     for (const [round, rank] of roundrank) {
       try {
         await axios.post(base_url + `/api/playoffgames/`+tournamentID, {
           round: round,
           rank: rank,
         });
       } catch (error) {
         console.error('Failed to create games', error);
       }
     }
    
   }
   fetchPlayoffGames();
   alert('Playoff Games added successfully!');
   
 };
  
  // const createPlayoffGames = async () => {
  //   const divisionA = sortedTeams.filter((team) => team.division.name === 'A');
  //   const divisionB = sortedTeams.filter((team) => team.division.name === 'B');
  
  //   if (divisionA.length > 0 && divisionB.length > 0) {
  //     const games = [];
  
  //     for (let i = 0; i < divisionA.length; i++) {
  //       const teamA = divisionA[i];
  //       const teamB = divisionB[divisionB.length - i - 1];
  
  //       try {
  //         const response = await axios.post(base_url + '/api/playoffgames', {
  //           team1: teamA,
  //           team2: teamB,
  //           round:1,
  //         });
  
  //         games.push(response.data);
          
  //       } catch (error) {
  //         console.error('Failed to create games', error);
  //       }
  //     }
  //     alert('Playoff Games added successfully!');
     
  //   }
  // };
  

  return (
    <div>
      <br />
      <h3> Option 1: Playoff with wildcard round</h3>
      <label>Number of team in WildCard round. i.e. 10 will pick bottom 10 team from standings to create 5 games.:</label>
      <br /><input type="number" value={wildcardCount} onChange={(e) => setwildcardCount(parseInt(e.target.value))} />

      <p>This button will create WildCard games based on team standings.</p>
      <button onClick={createWildCardGames}>Create WildCard Games</button>

      <br />
      <br />
       <label>Number of teams to drop/wildcard teams. i.e. if you used 10 team for wildcard games use 10 here. It will drop those 10 teams and instead pick winners of the wildcard round as bottom teams to create playoff games.:</label>
       <br /> <input type="number" value={dropCount} onChange={(e) => setDropCount(parseInt(e.target.value))} />

    
      <p>This button will create playoff games based on team standings.</p>
      <button onClick={createPlayoffGamesWildCard}>Create Playoff Games 1</button>
      <br/>
      <br/>
      <br/>
      <h3> Option 2: Playoff without a wildcard round</h3>


      <br />
       <label>Number of teams to drop for a playoff games. i.e. if you want to drop bottom 10 teams from standing use 10 here. THis option only allows max top 16 teams from team standings:</label>
       <br /> <input type="number" value={dropCount} onChange={(e) => setDropCount(parseInt(e.target.value))} />

    
      <p>This button will create playoff games based on team standings.</p>
      <button onClick={createPlayoffGames}>Create Playoff Games 2</button>

      <br/>
      <br/>
      <br/>

      <h2>Wild Card Score & Court Update</h2>
      <div>
        <table className="schedule-table">
          <thead>
            <tr>
              <th>
                Team 1
              </th>
              <th>
                Team 2
              </th>
              <th>
                Team 1 Score
              </th>
              <th>
                Team 2 Score
              </th>
              <th>
                Court
              </th>
              <th>
                Round
              </th>
              <th>
                Rank
              </th>
              <th>
                Update Court
              </th>
              <th>
                Update Team 1 Score
              </th>
              <th>
                Update Team 2 Score
              </th>
            </tr>
          </thead>
          <tbody>
            {playoffgames
             .filter((game) => game.isWildCard === true)
            .map((game) => (
              <tr key={game._id}>
                <td>{game.team1?.name || 'TBD'}</td>
                <td>{game.team2?.name || 'TBD'}</td>
                <td>{game.scoreTeam1}</td>
                <td>{game.scoreTeam2}</td>
                <td>{game.court}</td>
                <td>{game.round}</td>
                <td>{game.rank}</td>
                <td>  
                <input
                      type="number"
                      value={gameScores[game._id]?.court || ''}
                      onChange={(e) => handleCourtChange(e, game._id)}
                    />

                </td>
                <td>  
                  <input
                    type="number"
                    value={gameScores[game._id]?.scoreTeam1 || ''}
                    onChange={(e) => handleScoreChange(e, game._id, 1)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={gameScores[game._id]?.scoreTeam2 || ''}
                    onChange={(e) => handleScoreChange(e, game._id, 2)}
                  />                  
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div>
        <button onClick={updateAllPlayoffGame}>Update WildCard Game </button>

      
        </div>
      </div>

      <br />
      <br />
      <h2>Playoff Score & Court Update</h2>
      <div>
        <table className="schedule-table">
          <thead>
            <tr>
              <th>
                Team 1
              </th>
              <th>
                Team 2
              </th>
              <th>
                Team 1 Score
              </th>
              <th>
                Team 2 Score
              </th>
              <th>
                Court
              </th>
              <th>
                Round
              </th>
              <th>
                Rank
              </th>
              <th>
                Update Court
              </th>
              <th>
                Update Team 1 Score
              </th>
              <th>
                Update Team 2 Score
              </th>
            </tr>
          </thead>
          <tbody>
            {playoffgames
             .filter((game) => game.isWildCard === false)
            .map((game) => (
              <tr key={game._id}>
                <td>{game.team1?.name || 'TBD'}</td>
                <td>{game.team2?.name || 'TBD'}</td>
                <td>{game.scoreTeam1}</td>
                <td>{game.scoreTeam2}</td>
                <td>{game.court}</td>
                <td>{game.round}</td>
                <td>{game.rank}</td>
                <td>  
                <input
                      type="number"
                      value={gameScores[game._id]?.court || ''}
                      onChange={(e) => handleCourtChange(e, game._id)}
                    />

                </td>
                <td>  
                  <input
                    type="number"
                    value={gameScores[game._id]?.scoreTeam1 || ''}
                    onChange={(e) => handleScoreChange(e, game._id, 1)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={gameScores[game._id]?.scoreTeam2 || ''}
                    onChange={(e) => handleScoreChange(e, game._id, 2)}
                  />                  
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div>
        <button onClick={updateAllPlayoffGame}>Update Playoff Game </button>

      
        </div>
      </div>
    </div>
  );
};

export default AddPlayoff;